import React, { useState, useEffect } from "react";
import style from './index.module.less'
import selectIcon from './selectIcon.svg'
import BlackDown from '@/images/SVG/blackDown.svg'

const Select = ({ options, styles,setCheckedValue,defaultValue,disabled }) => {
    const [inputValue, setInputValue] = useState(defaultValue)
    const [inputLabel, setInputLabel] = useState('')
    const [optionOpen, setOptionOpen] = useState(false)
    useEffect(() => {
        let filterData = options?.filter(item => item.value == inputValue)
        setInputLabel(filterData[0].label)
    }, [inputValue])
    useEffect(()=>{
        setInputValue(defaultValue)
    },[defaultValue])
    return (
        <div
            className={style.select} tabIndex='-1'
            style={{...styles,cursor:disabled?'auto':"pointer",color:disabled?'rgba(0, 0, 0, 0.4)':"rgba(0, 0, 0, 0.8)"} }
            onClick={() => disabled?'':setOptionOpen(!optionOpen)}
            onBlur={() => setOptionOpen(false)}
        >
            {!disabled&&<img src={selectIcon} className={style.selectIcon} style={{transform:optionOpen?'translate(0, -50%) rotate(180deg)':"translate(0, -50%)"}}/>}
            
            {inputLabel}
            <div
                className={style.optionBox}
                style={{ maxHeight: optionOpen ? '300px' : "0" }}>

                {options.map((item, index) => (
                    <div className={style.optionItem} key={`option${index}`}
                        onClick={() => {
                            setInputValue(item.value);
                            setOptionOpen(false)
                            setCheckedValue(item.value)
                        }}
                        style={{
                            backgroundColor: item.value == inputValue ? 'rgba(0, 0, 0, 0.1)' : "#fff",
                            color: item.value == inputValue ? 'rgba(0, 0, 0, 0.8)' : "rgba(0, 0, 0, 0.6)"
                        }}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    )
}
const FaqSelect = ({ options, styles,setCheckedValue,defaultValue,disabled }) => {
    const [inputValue, setInputValue] = useState(defaultValue)
    const [inputLabel, setInputLabel] = useState('')
    const [optionOpen, setOptionOpen] = useState(false)
    useEffect(() => {
        let filterData = options?.filter(item => item == inputValue)
        setInputLabel(filterData[0])
    }, [inputValue])
    useEffect(()=>{
        setInputValue(defaultValue)
    },[defaultValue])
    return (
        <div
            className={style.faqselect} tabIndex='-1'
            style={{...styles,cursor:disabled?'auto':"pointer",color:disabled?'rgba(0, 0, 0, 0.4)':"rgba(0, 0, 0, 0.8)"} }
            onClick={() => disabled?'':setOptionOpen(!optionOpen)}
            onBlur={() => setOptionOpen(false)}
        >   
            <div style={{position:"relative"}}>{!disabled&&<BlackDown className={style.faqselectIcon} style={{transform:optionOpen?'translate(0, -50%) rotate(270deg)':"translate(0, -50%) rotate(90deg)"}}/>}
            
            {inputLabel}</div>
            
            <div
                className={style.optionBox}
                style={{ height: optionOpen ? 'auto' : "0" }}>

                {options.map((item, index) => (
                    <div className={style.optionItem} key={`option${index}`}
                        onClick={() => {
                            setInputValue(item);
                            setOptionOpen(false)
                            setCheckedValue(item)
                        }}
                        style={{
                            color: item == inputValue ? 'rgba(0, 0, 0, 0.8)' : "rgba(0, 0, 0, 0.6)"
                        }}
                    >
                        {item}
                    </div>
                ))}
            </div>
        </div>
    )
}
export { Select ,FaqSelect}