import React , {useState,useEffect} from "react";
import style from './index.module.less'

const TabWhite=({list,setTabType,defaultValue,defaultIndex})=>{
    const [tabIndex,setTabIndex]=useState(defaultValue)
  

    return(
        <div className={style.tab}  >
          <div className={style.tabList}>
            {list.map((item, index) => (
              <div className={style.tabListItem} key={item.text}
                style={{
                  color: tabIndex == item.type ? 'rgba(0, 0, 0, 0.8)' : "rgba(0, 0, 0, 0.6) ",
                  background: tabIndex == item.type ? '#fff' : 'transparent'
                }}
                onClick={() => {setTabType(item.type),setTabIndex(item.type)}}
              >
                {item.text}
              </div>
            ))}
          </div>

        </div>
    )
}

export default TabWhite