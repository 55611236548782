import React, { useContext, useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'
import { TabWhite } from '@/components/index'
import BlackDown from '@/images/SVG/blackDown.svg'
import useBigScreen from "@/hooks/bigScreen"
import { FaqSelect } from '@/components/input/index'
import Axios from "axios"
import getCountry from '@/utils/getCountry.js'
import { useIntl } from "react-intl"


function Index() {
  const intl = useIntl()
  const ifPc = useBigScreen(768)
  const region = getCountry()
  const tabList = [{
    type: "products",
    text: intl.formatMessage({ id: "products_text", defaultMessage: "Product" }),
  },
  {
    type: "order-help",
    text: intl.formatMessage({ id: "support_faq_order", defaultMessage: "Order Help" }),
  },
  {
    type: "after-sales",
    text: intl.formatMessage({ id: "after_sale", defaultMessage: "After Sale" }),
  },
  ]
  const [proTabArr, setProTabArr] = useState([])
  const [tabType, setTabType] = useState('') //控制大分类
  const [selectType, setSelectType] = useState('') //如果一个大分类下有许多小分类 控制这个选择
  const [questionTypeArr, setQuestionTypeArr] = useState([])
  const [questionType, setQuestionType] = useState('')
  const [allList, setAllList] = useState([])
  const [showList, setShowList] = useState([]) //最终展示的faq列表

  const [defaultData, setDefault] = useState(window.location.href.split('#')[1] || tabList[0].type)
  useEffect(() => {
    if (window.location.href.includes('products')) {
      setTabType('products')
    } else if (window.location.href.includes('after-sales')) {
      setTabType('after-sales')
    } else if (window.location.href.includes('order-help')) {
      setTabType('order-help')
    } else {
      setTabType('products')
    }
  }, [])
  useEffect(() => {

    if (tabType == 'products') {
      Axios.get(`${process.env.resourceUrl}docs/faq/${region}/pro-tab.json`).then(function (data) {
        setProTabArr(data.data)
        setSelectType(data.data[0].type)
        getQuestionArr(data.data[0].type)
      })
    } else {
      if (!!tabType) {
        getQuestionArr(tabType)
      }
    }
  }, [tabType])
  useEffect(() => {
    if (!!selectType) {
      getQuestionArr(selectType)

    }
  }, [selectType])

  useEffect(() => {

    if (!!questionType) {
      let showArr = allList.filter(item => item.Type == questionType)
      setShowList(showArr)
    }

  }, [questionType])

  const getQuestionArr = (select) => {
   
    Axios.get(`${process.env.resourceUrl}docs/faq/${region}/${select}.json`).then(function (data) {
      setAllList(data.data)
      let obj = {}
      data.data.map(item => {
        obj[item.Type] = 1
      })
      if (Object.keys(obj)[0] == questionType) {
        let showArr = data.data.filter(item => item.Type == Object.keys(obj)[0])
        setShowList(showArr)
      }
      setQuestionTypeArr(Object.keys(obj))
      setQuestionType(Object.keys(obj)[0])


    })
  }

  const clickFaqItem = (e) => {
    const answerNode = e.currentTarget.childNodes[1]
    answerNode.style.display = (answerNode.style.display == 'none' ? 'block' : "none")

    const svgNode = e.currentTarget.childNodes[0].childNodes[1]
    svgNode.style.transform = (svgNode.style.transform == 'rotate(90deg)' ? 'rotate(270deg)' : "rotate(90deg)")
  }

  return (
    <Layout menuBlack={true} footerBackground='#fff' footerTextWhite={false}>
      <SEO page="FAQ" />
      <div className={style.faq}>
        <div className={style.faqHeader} >
          FAQ
        </div>
        <div className={style.faqBody}>
          <TabWhite list={tabList} setTabType={setTabType} type='faq' defaultValue={defaultData} />
        </div>
        {tabType == 'products' &&
          <div className={style.typeTab}>
            <div className={style.typeTabBody}>
              {proTabArr.map(item => (
                <div className={style.typeTabBodyItem} key={`faqtype${item.type}`}
                  onClick={() => setSelectType(item.type)}
                  style={{
                    color: selectType == item.type ? '#FF3300' : 'rgba(0, 0, 0, 0.4)'
                  }} >{item.value}
                  <span style={{ opacity: selectType == item.type ? 1 : 0 }}></span>
                </div>
              ))}
            </div>
          </div>
        }

        <div className={style.faqContent}>
          {ifPc ? <div className={style.faqContentSelect}>
            {questionTypeArr.map(item => (
              <div className={style.faqContentSelectOption}
                style={{ color: questionType == item ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.6)', fontWeight: questionType == item ? 600 : 500 }}
                key={`faqtabTypeSelect${item}`} onClick={() => setQuestionType(item)}>{item}</div>
            ))}
          </div> :
            <div className={style.faqSelectMobile}>
              <FaqSelect
                setCheckedValue={setQuestionType}
                options={questionTypeArr}
                defaultValue={questionType}

              />

            </div>}

          <div className={style.faqShow}>
            <div className={style.faqShowType}>{questionType}</div>
            <div className={style.faqShowList}>
              {showList.map((item, index) => (
                <div className={style.faqShowListItem} key={`faqShowList${index}`}
                  onClick={(e) => { clickFaqItem(e) }}
                >
                  <div className={style.faqShowListItemQ}>
                    <div className={style.faqShowListItemQBox}>
                      {/* <span /> */}
                      {item.Question}
                    </div>
                    <BlackDown style={{ transform: "rotate(90deg)" }} />
                  </div>
                  {/* <div className={style.faqShowListA} style={{ display: 'none' }}>
                    {item.Answer}
                  </div> */}
                  <div className={style.faqShowListA} style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: `${item.Answer}` }}  ></div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Index
